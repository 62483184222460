/**
 * Slider gallery init
 *
 * @since   1.0.0
 * @package wine-festival
 */
(function($) {
	$(document).ready(function() {

		var $slider = $( '.slider.gallery' );

        // sanity check flickity
        if ( ! ( 'flickity' in $.fn ) || ! ( 'featherlight' in $.fn ) || $slider.length < 1 ) {
            return;
		}

		$slider.flickity({
			cellSelector : 'figure',
			draggable    : false,
			cellAlign    : 'left',
			contain      : true,
			pageDots     : false,
		});

		// use Font Awesome Icons
		$( '.flickity-prev-next-button.previous', $slider ).html( '<i class="far fa-long-arrow-alt-left"></i>' );
		$( '.flickity-prev-next-button.next', $slider ).html( '<i class="far fa-long-arrow-alt-right"></i>' );

	}); // document.ready()
})(jQuery);
