/**
 * Mobile Navigation Animation
 *
 * @since   1.0.0
 * @package wine-festival
 */

(function($) {
	$(document).ready(function() {

		// things
		var $window         = $( window );
		var header          = $( 'header[role=banner]' );
		var nav             = $( 'nav', header );
		var navToggle       = $( '#menu-toggle', header );
		var adminBar        = $( '#wpadminbar' );
		var navOverlay      = $( '.nav-overlay', header );
		var parentMenuItems = $( '.menu-item-has-children' );
		var subMenuToggles;

		// heights
		var windowHeight;
		var headerHeight;
		var navHeight;
		var adminBarHeight;

		// init stuff
		maybeScrollY();
		addSubmenuToggles();

		// bind events
		$window       .resize( maybeScrollY  );
		navToggle     .click ( toggleMenu    );
		subMenuToggles.click ( toggleSubmenu );
		navOverlay    .click ( toggleMenu    );


		/**
		 * Toggler: toggle class and position menu
		 *
		 * @since 1.0.0
		 */
		function toggleMenu() {

			nav.toggleClass( 'toggled' );
			navToggle.toggleClass( 'toggled' );
			navOverlay.fadeToggle();
		}


		/**
		 * Get the header, nav, and admin bar
		 * heights
		 *
		 * @since 1.0.0
		 */
		function getHeights() {
			windowHeight   = $window.height();
			headerHeight   = header.outerHeight();
			navHeight      = nav.outerHeight();
			adminBarHeight = adminBar.outerHeight();
		}


		/**
		 * Maybe scroll overflow-y
		 *
		 * If the height of the menu goes beyond
		 * what's visible on the screen, allow
		 * some scrolling
		 *
		 * @since 1.0.0
		 */
		function maybeScrollY() {

			// reset scroll-y so height is
			// calculated correctly
			nav.removeClass( 'scroll-y' );

			// get our heights
			getHeights();

			// calculate usable height
			var usableHeight = windowHeight - headerHeight - adminBarHeight;

			// compare
			if ( usableHeight < navHeight ) {
				nav.addClass( 'scroll-y' );
			}
		}


		/**
		 * Add mobile submenu dropdown toggles,
		 * then bind them to toggles var
		 *
		 * @since 1.0.0
		 */
		function addSubmenuToggles() {

			parentMenuItems.append(
				'<button class="toggle-sub"><span class="screen-reader-text">Menu</span></button>'
			);

			subMenuToggles = $( '.toggle-sub', parentMenuItems );
		}


		/**
		 * Toggle mobile submenu
		 *
		 * @since 1.0.0
		 */
		function toggleSubmenu() {
			var li = $(this).parent();
			li.toggleClass( 'sub-toggled' );

			// double-check the scroll-y
			maybeScrollY();
		}

	}); // document.ready
})(jQuery);
