/**
 * Do things with header on scroll
 *
 * @since   1.0.0
 * @package wine-festival
 */
(function($) {
	$(document).ready(function() {

		var $document = $( document );
		var $header   = $( 'header[role="banner"]' );

		checkHeaderScroll();
		$document.on( 'scroll', checkHeaderScroll );

		/**
		 * Check scroll distance
		 *
		 * @since 1.0.0
		 */
		function checkHeaderScroll() {

			if ( $document.scrollTop() > 250 ) {
				$header.addClass( 'scrolled' );
			} else {
				$header.removeClass( 'scrolled' );
			}
		};

	}); // document.ready()
})(jQuery);