/**
 * Add <li> icons
 *
 * @since   1.0.0
 * @package wine-festival
 */

(function($) {
	$(document).ready(function() {

		var $ul = $( '.entry-content ul, ul.bulleted' );
		var $li = $( '> li', $ul );

		$li.prepend( '<i class="far fa-plus"></i>' );

	}); // document.ready
})(jQuery);
